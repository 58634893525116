import styled from "@emotion/styled"
import React from "react"
import { Container } from "react-grid-system"
import Breadcrumbs from "../components/breadcrumbs"
import SEO from "../components/seo"
import { H1 } from "../components/UI/typography"

export default ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
    title,
    content,
  } = pageContext
  const crumbLabel = title.length > 10 ? title.substring(0, 10).concat("...") : title
  return (
    <Container>
      <SEO title={title} />
      <Breadcrumbs crumbs={crumbs} label={crumbLabel} />
      <H1>{title}</H1>
      <Content
        className="post-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Container>
  )
}

const Content = styled.div``
